import React, { useEffect, useState } from "react";
import "./clientlist.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  //TablePagination,
  Pagination,
  Modal,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStores } from "../../models";
import { useHistory, Redirect, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Auth } from "aws-amplify";
import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  errorToast,
  convertAmount,
  successToast,
  sorting,
} from "../../utils/helper";
import {
  ContentLoadingLoader,
  SampleToast,
  CustomTooltip,
  AddNewClient,
  SendKycLinkModel,
  WithdrawalModel,
  LabelWithSortComponent,
  FullScreenLoader,
  ClientOTPModelV2,
  ClientSwitchPlanModal,
} from "../../components";
import { IFA_KYC_API } from "../../services/api/IFA Kyc/ifa-kyc-api";
import { Client_API } from "../../services/api";
import { User_API, WithdrawalApi, WalletApi } from "../../services/api";
import WalletLoadingModel from "../../components/wallet-loading-model/wallet-loading-model";
import { eventLog } from "../../utils/firebase";
import { Profile_API } from "../../services/api/Profile/profile-api";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import { KYC_LINK_API_V2 } from "../../services/api";
import config from "../../config/settings";
import { KYC_API_V2 } from "../../services/api";
import Loader from "react-loader-spinner";

const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "hidden",
};

const tableHeader = {
  backgroundColor: "#f6f6f6",
  fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
  width: 200,
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const ClientList = React.memo(observer(function Clientlist(props) {
  const [linkErrors, setLinkErrors] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [render, setRender] = useState(false);
  const rootStore = useStores();
  const navigate = useHistory();
  const param = useLocation();
  const [isLogin, setIsLogin] = useState();
  const infoText = "The sum of all amounts transferred back to bank account";
  const [loginRender, setLoginRender] = useState(false);
  const [showModel, setShowModel] = useState(false);
  const [disable, setDisable] = useState(false);
  const api = new IFA_KYC_API();
  const api1 = new KYC_API_V2();
  const api2 = new Client_API();
  const [isMatured, setIsMatured] = useState(0);
  const [openModel, setOpenModel] = useState(false);
  const [openWalletLoadingModel, setOpenWalletLoadingModel] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const withdrawalApi = new WithdrawalApi();
  const walletApi = new WalletApi();
  const [profileData, setProfileData] = useState({});
  const [loader, setLoader] = useState(false);
  const [amountInvalid, setAmountInvalid] = useState(false);
  const [amountInvalidText, setAmountInvalidText] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [fetchingTableData, setFetchingTableData] = useState(false);
  const [totalClients, setTotalClients] = useState(false);
  const [ctaLoader, setCtaLoader] = useState(false);
  const [showOtpModel, setShowOtpModel] = useState(false);
  const [clientConsentModelLoader, setClientConsentModelLoader] =
    useState(false);
  const [clientOTP, setClientOTP] = useState("");
  const [ifaCognitoId, setIfaCognitoId] = useState("");
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [consentUserId, setConsentUserId] = useState("");
  const [consentButtonDisabled, setConsentButtonDisabled] = useState(false);
  const [soaStatus, setSoaStatus] = useState(0);
  const [investmentStatus, setInvestmentStatus] = useState(0);
  const [showSwitchPlanRequestModal, setShowSwitchPlanRequestModal] =
    useState(false);
  const [switchPlanClient, setSwitchPlanClient] = useState({});

  const asc_label_sort = {
    name: true,
    withdrawable: false,
    locked_funds: false,
    aum: false,
    received_amount: false,
    daily_growth: false,
    overall_fund: false,
    created_at: false,
  };

  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("name");
  const [selectedType, setSelectedType] = useState("string");

  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
        fetchIfakycStatus();
        }
      } catch {
        setIsLogin(false);
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    const api = new kycStatus_API();
    // fetch data
    props.showCommonSidebar(true);
          setLoader(false);
          // fetchClientList(1);
          setIsLogin(true);
          setLoginRender(true);
    // api
    //   .getkycStatusData()
    //   .then((res) => {
    //     if (res.kind === "ok") {
          
    //     } else {
    //       setLoader(false);
    //       navigate.push("/");
    //     }
    //   })
    //   .catch((error) => {
    //     setLoader(false);
    //     errorToast(
    //       "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
    //     );
    //   });
  };
  // get cognito id
  useEffect(() => {
    let getCredentials = async () => {
      const user = await Auth.currentCredentials();
      setIfaCognitoId(user.identityId);
    };
    return getCredentials();
  }, []);

  useEffect(() => {
    if (isLogin) {
      setPage(0);
      fetchClientList(1, searchByName);
    }
  }, [asc]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      requestSearch();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchByName]);

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  }

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // fetch the client list
  const fetchClientList = (queryPage, searchName) => {
    setFetchingTableData(true);
    rootStore.clientList
      .getClientlist(
        isMatured,
        0,
        false,
        queryPage,
        rowsPerPage,
        searchName,
        selected,
        asc[selected],
        param.state && param.state.title && param.state.title == "idle"
          ? true
          : false
      )
      .then((res) => {
        if (res.kind === "ok") {
          var tempData = res.data.map((item) => ({
            ...item,
            view_more: false,
          }));
          setClientData(tempData);
          setFilterData(tempData);
          setRender(true);
          setFetchingTableData(false);
          if (res.meta && res.meta.pagination && res.meta.pagination.count) {
            setTotalClients(res.meta.pagination.count);
          }
          eventLog({
            type: "fetch_clients",
            action: {
              trigger: "true",
            },
          });
        } else {
          setRender(true);
          setFetchingTableData(false);
          eventLog({
            type: "fetch_clients",
            action: {
              trigger: "false",
            },
          });
          errorToast(res.error);
        }
      });
  };

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9\.]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  // close model
  const handleClose = () => {
    setAmount(0);
    setSelectedIndex(null);
    setOpenModel(false);
  };

  const handleWalletLoadingModelClose = () => {
    setOpenWalletLoadingModel(false);
  };

  // on wallet loading proceed click
  const onWalletLoadingProceed = async () => {
    if (!isNaN(parseFloat(amount))) {
      if (amount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (amount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (amount % 1000 != 0) {
        errorToast("Amount should be in multiple of 1000.");
      } else {
        setLoading(true);
        let payload = {
          user_id: clientData[selectedIndex].uuid,
          amount: parseFloat(amount),
        };
        await walletApi.postWalletRequest(payload).then((res) => {
          if (res.kind === "ok") {
            successToast("Wallet loading request sent successfully.");
            setLoading(false);
            setOpenWalletLoadingModel(false);
            eventLog({
              type: "wallet_request",
              action: {
                trigger: "true",
              },
            });
          } else {
            eventLog({
              type: "wallet_request",
              action: {
                trigger: "false",
              },
            });
            errorToast(res.error);
            setLoading(false);
            setOpenWalletLoadingModel(false);
          }
        });
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  // on proceed click
  const onProceed = async () => {
    const withdrawableLimit = config.withdrawable_limit;
    const availFunds = clientData[selectedIndex].available_funds;
    if (!isNaN(parseFloat(amount))) {
      if (amount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (amount > parseFloat(availFunds)) {
        errorToast("Amount cannot be greater than available balance.");
      } else if (amount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (amount < 1) {
        errorToast("The minimum amount that can be withdrawn is ₹ 1.");
      } else if (amount > withdrawableLimit) {
        errorToast(
          `Amount cannot be greater than ₹ ${convertAmount(withdrawableLimit)}`
        );
      } else {
        setLoading(true);
        let payload = {
          user_id: clientData[selectedIndex].uuid,
          amount: parseFloat(amount),
          is_ifa: true,
        };
        await withdrawalApi.updateWithdrawalRequest(payload).then((res) => {
          if (res.kind === "ok") {
            setTimeout(() => {
              successToast(res.data);
              setLoading(false);
              setOpenModel(false);
              eventLog({
                type: "withdrawal_request",
                action: {
                  trigger: "true",
                },
              });
            }, 3000);
          } else {
            eventLog({
              type: "withdrawal_request",
              action: {
                trigger: "false",
              },
            });
            errorToast(res.error);
            setLoading(false);
          }
        });
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  // request search function by name

  const requestSearch = () => {
    setPage(0);
    fetchClientList(1, searchByName);
  };

  // handle page change function
  const handleChangePage = (event, newPage) => {
    setPage(newPage - 1);
    fetchClientList(newPage, searchByName);
  };

  // send kyc link
  const sendKycLink = async (data) => {
    // payload
    let payload = {
      name: data.name.trim(),
      email: data.email,
      mobile: data.mobile,
    };
    const api = new KYC_LINK_API_V2();
    // call the api
    await api.sendKycLink(payload).then((res) => {
      if (res.kind === "ok") {
        successToast("Onboarding link sent successfully.");
        eventLog({
          type: "kyc_link_sent",
          action: {
            trigger: "true",
          },
        });
        setTimeout(() => {
          setDisable(false);
          setShowModel(false);
        }, 2000);
      } else {
        errorToast(res.error);
        setDisable(false);
        window.scrollTo(0, 0);
      }
    });
  };

  const handleSendWalletLoadingLink = async (client) => {
    setOpenWalletLoadingModel(true);
  };

  // fetch ifa profile data
  const fetchIfaProfileData = async () => {
    setLoader(true);
    const api = new Profile_API();

    // fetch data
    await api.getIFAProfileData().then((res) => {
      if (res.kind === "ok") {
        setProfileData(res.data);
        setLoader(false);
        setShowModel(true);
      } else {
        setLoader(false);
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
      }
    });
  };

  const handleSendTcLink = async (index) => {
    //payload for terms and condition api
    let payloadForTermsConditionMail = {
      email: clientData[index].email,
      mobile: clientData[index].mobile,
      name: clientData[index].name,
      nominee_obj: clientData[index].nominee_details,
    };
    //call terms and condition api
    await api1
      .sentTermsAndCondtionMail(payloadForTermsConditionMail)
      .then((resp) => {
        if (resp.kind === "ok") {
          successToast("Terms & Conditions has been sent successfully");
          setCtaLoader(false);
        } else {
          errorToast(resp.error);
          setCtaLoader(false);
        }
      });
  };

  const handleShowOtp = () => {
    setShowOtpModel(true);
  };

  const verifyOTP = async () => {
    // payload
    let payload = {
      otp: clientOTP,
      ifa_id: ifaCognitoId,
      pan: String(clientData[selectedIndex].pan).toUpperCase(),
      mobile: clientData[selectedIndex].mobile,
      email: clientData[selectedIndex].email,
      mode: "ifa",
    };

    await api1.verifyClientConsent(payload).then((res) => {
      if (res.kind === "ok") {
        // if  success
        successToast(res.message);
        setClientConsentModelLoader(false);
        setShowOtpModel(false);
      } else {
        errorToast(res.error);
        setClientConsentModelLoader(false);
      }
    });
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  //Handle view more
  const handleViewMore = (index) => {
    let data = clientData.map((item) => {
      if (index === item.uuid) {
        item.view_more = !item.view_more;
      } else {
        item.view_more = false;
      }
      return item;
    });
    setClientData(() => [...data]);
  };

  const handleInvestmentConsent = async () => {
    let payload = {
      client_id: consentUserId,
      ifa_id: ifaCognitoId,
      status: 1,
      soa_status: 1,
    };
    await api2.postClientInvestmentConsent(payload).then((res) => {
      eventLog({
        type: "send_investment_consent",
        action: {
          trigger: "true",
        },
      });
      if (res.kind === "ok") {
        eventLog({
          type: "sent_consent_mail",
          action: {
            trigger: "true",
          },
        });
        setCtaLoader(false);
        setConsentButtonDisabled(false);
        successToast("Consent request has been sent successfully");
        setLoading(false);
        setShowConsentModal(false);
      } else {
        eventLog({
          type: "sent_consent_mail",
          action: {
            trigger: "false",
          },
        });
        errorToast(res.error);
        setLoading(false);
        setCtaLoader(false);
        setConsentButtonDisabled(false);
      }
    });
  };

  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="clientlist_div_container">
          {/* consent modal */}
          {showConsentModal ? (
            <Modal
              open={showConsentModal}
              onClose={() => {
                setConsentUserId("");
                setShowConsentModal(false);
              }}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{
                backgroundColor: "rgba(0, 0, 0, 0.699)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="consent_modal">
                <div
                  className="consent_modal_header"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    backgroundColor: "#f2f2f2",
                  }}
                >
                  <h2
                    style={{
                      fontFamily: "Lato",
                      fontWeight: 500,
                      fontSize: 20,
                      color: "#343434",
                      paddingLeft: 40,
                      paddingTop: 10,
                    }}
                  >
                    Send Consent Request to Investor
                  </h2>
                  <div
                    style={{
                      fontSize: 28,
                      paddingTop: 20,
                      paddingRight: 40,
                      paddingBottom: 15,
                    }}
                  >
                    <p
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setConsentUserId("");
                        setShowConsentModal(false);
                      }}
                    >
                      <CloseIcon />
                    </p>
                  </div>
                </div>
                <div className="consent_modal_content">
                  <p
                    style={{
                      fontSize: 14,
                      fontFamily: "Lato",
                      paddingLeft: 80,
                      paddingRight: 80,
                      paddingTop: 15,
                      lineHeight: 1.5,
                    }}
                  >
                    An email will be sent to the investor to provide consent to
                    you for the following:
                  </p>
                  <ul
                    style={{
                      fontSize: 14,
                      fontFamily: "Lato",
                      paddingLeft: 100,
                      paddingRight: 80,
                      paddingTop: 20,
                      lineHeight: 1.5,
                    }}
                  >
                    {soaStatus !== 2 ? (
                      <li
                        style={{
                          paddingLeft: 2,
                          paddingBottom: 13,
                        }}
                      >
                        To access any reports, certificates or statements linked
                        to their investments on 1Invest.
                      </li>
                    ) : null}
                    {investmentStatus !== 2 ? (
                      <li
                        style={{
                          paddingLeft: 2,
                        }}
                      >
                        To act on their behalf for selecting the 1Invest plan
                        and investing funds from their escrow account maintained
                        with RNVP Technology Private Limited. This authorization
                        is revocable at any time, for any reason.
                      </li>
                    ) : null}
                  </ul>
                </div>
                <div
                  className="consent_modal_button"
                  style={{
                    paddingTop: "10%",
                    paddingLeft: "31%",
                  }}
                >
                  <button
                    className="consent_button"
                    onClick={() => {
                      setCtaLoader(true);
                      setConsentButtonDisabled(true);
                      handleInvestmentConsent();
                    }}
                    disabled={consentButtonDisabled}
                  >
                    {ctaLoader ? (
                      <Loader
                        type="TailSpin"
                        color="#FFFFFF"
                        height={15}
                        width={15}
                      />
                    ) : (
                      "Send Consent Request"
                    )}
                  </button>
                </div>
              </div>
            </Modal>
          ) : (
            <div />
          )}
          {/* table */}
          <div>
            {render === false ? (
              <ContentLoadingLoader />
            ) : (
              <div className="clientlist_div_tablecontainer">
                {/* header */}
                <div className="clientlist_header_div">
                  {/* text */}
                  <h1 className="clientlist_header_div_title">Clients</h1>
                  {/* Add new client button */}
                  <AddNewClient
                    sendLink={() => fetchIfaProfileData()}
                    addClient={() => {
                      navigate.push("/clients/kyc", {
                        uuid: "",
                        disable: false,
                      });
                      eventLog({
                        type: "add_client_kyc ",
                        action: {
                          trigger: "true",
                        },
                      });
                    }}
                  />
                </div>

                {/* search_bar */}
                <div className="clientlist_search_bar_div">
                  <div className="clientlist_search_bar">
                    {/* icon */}
                    <SearchIcon
                      style={{
                        fontSize: "20px",
                        paddingRight: "10px",
                        color: "#555555",
                      }}
                    />
                    {/* input box */}
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="clientlist_search_bar_input"
                      value={searchByName}
                      onChange={(e) => {
                        setSearchByName(e.target.value);
                      }}
                    />
                  </div>
                </div>

                {/* table */}
                <TableContainer style={tableContainer}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          padding="none"
                          style={{
                            ...tableHeader,
                            position: "sticky",
                            left: 0,
                            zIndex: 100,
                          }}
                        >
                          <LabelWithSortComponent
                            label="Client Name"
                            id="name"
                            type="string"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          Mobile Number
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          {/*Earlier the column name was Available Funds*/}
                          <LabelWithSortComponent
                            label="Investor ID"
                            id="withdrawable"
                            type="number"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          {/*Earlier the column name was Invested Amount*/}
                          <LabelWithSortComponent
                            label="Total Lent Amount"
                            id="aum"
                            type="number"
                            asc={asc}
                            selected={selected}
                            setAsc={setAsc}
                            setSelected={setSelected}
                            setSelectedType={setSelectedType}
                          />
                        </TableCell>
                        <TableCell
                          align="left"
                          padding="none"
                          style={tableHeader}
                        >
                          More Details
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ opacity: fetchingTableData ? 0.3 : 1 }}>
                      {clientData.map((row, index) => (
                        <>
                          <TableRow
                            key={row.uuid}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#fafafa" : "#fff",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleViewMore(row.uuid);
                            }}
                          >
                            <TableCell
                              style={{
                                ...tableRow,
                                position: "sticky",
                                left: 0,
                                background:
                                  index % 2 === 0 ? "#fafafa" : "#fff",
                                zIndex: 100,
                              }}
                              align="left"
                            >
                              <p
                                className="clientlist_client_name"
                                style={{
                                  color: "#3A86FF",
                                }}
                                onClick={() => {
                                  if (row.kyc_status == 2) {
                                    navigate.push("/clients/investments", {
                                      name: row.name,
                                      uuid: row.uuid,
                                      net_worth: row.net_worth,
                                    });
                                    eventLog({
                                      type: "client_investments",
                                      action: {
                                        trigger: "true",
                                      },
                                    });
                                  } else {
                                    navigate.push("/clients/kyc", {
                                      uuid: row.uuid,
                                    });
                                  }
                                }}
                              >
                                {row.name}
                              </p>
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              {row.mobile}
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                              <CustomTooltip
                              // info={
                              //   <div>
                              //     Wallet Withdrawable: ₹
                              //     {convertAmount(row.withdrawable)} <br />
                              //     Freedom Plan: ₹
                              //     {convertAmount(
                              //       row.freedom_plan_withdrawable
                              //     )}
                              //   </div>
                              // }
                              >
                                <div>
                                  {row.investor_id}
                                </div>
                              </CustomTooltip>
                            </TableCell>
                            <TableCell style={tableRow} align="left">
                            ₹ {new Intl.NumberFormat('en-IN').format(row.total_lent_amount)}
                            </TableCell>
                            <TableCell
                              onClick={() => {
                                handleViewMore(row.uuid);
                              }}
                              style={tableRow}
                              align="left"
                            >
                              {!row.view_more ? (
                                <p>
                                  <KeyboardArrowDownIcon
                                    onClick={() => {
                                      handleViewMore(row.uuid);
                                    }}
                                    style={{
                                      color: "#000000",
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </p>
                              ) : (
                                <p>
                                  <KeyboardArrowUpIcon
                                    onClick={() => {
                                      handleViewMore(row.uuid);
                                    }}
                                    style={{
                                      color: "#000000",
                                      fontSize: "25px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </p>
                              )}
                            </TableCell>
                          </TableRow>

                          {/* View more details row*/}
                          {row.view_more ? (
                            <TableRow
                              style={{ position: "sticky", top: 0, left: 0 }}
                            >
                              {/* kyc status */}
                              <TableCell
                                style={{
                                  margin: 0,
                                  padding: 5,
                                  width: "66px",
                                  height: "42px",
                                  flexShrink: 0,
                                }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 5,
                                    color: "#898989",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                  align="left"
                                >
                                  KYC Status
                                </p>
                                <p
                                  style={{
                                    color:
                                      row.remarks_color == ""
                                        ? "transparent"
                                        : `${row.remarks_color}99`,
                                    margin: 0,
                                    padding: 5,
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                  align="left"
                                >
                                  {row.remarks}
                                </p>
                              </TableCell>

                              {/* fund withdrawn */}
                              <TableCell
                                style={{
                                  margin: 0,
                                  padding: 5,
                                  width: "106px",
                                  height: "38.189px",
                                  flexShrink: 0,
                                }}
                              >
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 5,
                                    color: "#898989",
                                    fontSize: "12px",
                                    fontWeight: 500,
                                  }}
                                  align="left"
                                >
                                  Total Repaid Amount
                                </p>
                                <p
                                  style={{
                                    margin: 0,
                                    padding: 5,
                                    color: "#1C1C1C",
                                    fontSize: "14px",
                                    fontWeight: 500,
                                  }}
                                  align="left"
                                >
                                  ₹ {new Intl.NumberFormat('en-IN').format(row.total_repaid_amount)}
                                </p>
                              </TableCell>

                              {/* send wallet loading request OR send terms and conditions  */}
                              {/* <TableCell
                                style={{
                                  margin: 0,
                                  padding: 5,
                                  width: "66px",
                                  height: "42px",
                                  flexShrink: 0,
                                }}
                              >
                                <>
                                  {row.kyc_status == 2 ||
                                  (row.kyc_status == 1 &&
                                    row.tc_status == 2) ? (
                                    <p
                                      className="table_button_text"
                                      style={{
                                        cursor:
                                          row.kyc_status == 2
                                            ? "pointer"
                                            : "not-allowed",
                                        filter:
                                          row.kyc_status == 2
                                            ? ""
                                            : "grayscale(1)",
                                      }}
                                      onClick={() => {
                                        if (row.kyc_status == 2) {
                                          setSelectedIndex(index);
                                          handleSendWalletLoadingLink(row);
                                        }
                                      }}
                                    >
                                      {`Send Wallet Loading \nRequest`}
                                    </p>
                                  ) : row.tc_status == 1 ? (
                                    <p
                                      className="table_button_text"
                                      onClick={() => {
                                        setCtaLoader(true);
                                        setSelectedIndex(index);
                                        handleSendTcLink(index);
                                      }}
                                    >
                                      {ctaLoader ? (
                                        <Loader
                                          type="TailSpin"
                                          color="#3a86ff"
                                          height={16}
                                          width={16}
                                        />
                                      ) : (
                                        "Resend Terms & Conditions"
                                      )}
                                    </p>
                                  ) : null}
                                </>
                              </TableCell> */}

                              {/* send withdrwal request OR enter otp  */}
                              {/* <TableCell
                                style={{
                                  margin: 0,
                                  padding: 5,
                                  width: "66px",
                                  height: "42px",
                                  flexShrink: 0,
                                }}
                              >
                                <>
                                  {row.kyc_status == 2 ||
                                  (row.kyc_status == 1 &&
                                    row.tc_status == 2) ? (
                                    <p
                                      className="table_button_text"
                                      style={{
                                        cursor:
                                          row.available_funds > 0
                                            ? "pointer"
                                            : "not-allowed",
                                        filter:
                                          row.available_funds > 0
                                            ? ""
                                            : "grayscale(1)",
                                      }}
                                      onClick={() => {
                                         // if (row.available_funds > 0) {
                                         //   setSelectedIndex(index);
                                         //   setOpenModel(true);
                                         // }
                                         handleButtonClick()
                                      }}
                                    >
                                      {`Send Withdrawal \nRequest`}
                                    </p>
                                  ) : row.tc_status == 1 ? (
                                    <p
                                      className="table_button_text"
                                      onClick={() => {
                                        setSelectedIndex(index);
                                        handleShowOtp();
                                      }}
                                    >
                                      Enter OTP
                                    </p>
                                  ) : null}
                                </>
                              </TableCell> */}

                              {/* send consent request and switch plan */}
                              {/* <TableCell
                                style={{
                                  margin: 0,
                                  padding: 5,
                                  width: "66px",
                                  height: "42px",
                                  flexShrink: 0,
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                  }}
                                >
                                  <>
                                    {row.kyc_status == 2 &&
                                    (row.consent_status != 2 ||
                                      row.soa_consent != 2) ? (
                                      <p
                                        className="table_button_text"
                                        onClick={() => {
                                          setConsentUserId(row.uuid);
                                          setSoaStatus(row.soa_consent);
                                          setInvestmentStatus(
                                            row.consent_status
                                          );
                                          setShowConsentModal(true);
                                        }}
                                      >
                                        {`Send Consent \nRequest`}
                                      </p>
                                    ) : null}
                                  </>

                                  {/* <>
                                    {row.freedom_plan_withdrawable >= 10000 ? (
                                      <p
                                        className="table_button_text"
                                        style={{
                                          marginLeft: 15,
                                        }}
                                        onClick={() => {
                                          setSwitchPlanClient(row);
                                          setShowSwitchPlanRequestModal(true);
                                        }}
                                      >
                                        {`Send Switch Plan \nRequest`}
                                      </p>
                                    ) : null}
                                  </> *
                                </div>
                              </TableCell> */}
                            </TableRow>
                          ) : null}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                  {/* if no data available */}
                  {clientData.length === 0 ? (
                    <div className="no_row_found_div">
                      <FindInPageIcon
                        style={{
                          fontSize: "100px",
                          color: "#555555",
                        }}
                      />
                      <p className="no_row_found">No row found</p>
                    </div>
                  ) : (
                    <div />
                  )}
                </TableContainer>

                {/* pagination */}
                <div className="pagination_div_client">
                  {/* <TablePagination
                    rowsPerPageOptions={[]}
                    component="div"
                    count={clientData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                  /> */}
                  <Pagination
                    color="standard"
                    count={Math.ceil(totalClients / rowsPerPage)}
                    page={page + 1}
                    variant="outlined"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                    onChange={handleChangePage}
                    shape="rounded"
                  />
                </div>

                {isPopupOpen && (
                   <div className="popup">
                     <div className="popup-content">
                       <h5>To comply with the latest regulatory changes in NBFC-P2P dated 17 August 2024, withdrawals have been halted across all accounts. All maturities, including those under the Freedom Plan, will now be processed based on borrower mapping and repaid as per borrower repayments.</h5>
                       <button className="popup-button" onClick={closePopup}>Close</button>
                     </div>
                   </div>
	              )}

                {/* withdrwal modal */}
                <WithdrawalModel
                  openModel={openModel}
                  handleClose={handleClose}
                  clientData={clientData}
                  selectedIndex={selectedIndex}
                  onKeyPress={onKeyPress}
                  setAmount={(value) => setAmount(value)}
                  loading={loading}
                  onProceed={() => onProceed()}
                />

                {/* Wallet loading link */}
                <WalletLoadingModel
                  openModel={openWalletLoadingModel}
                  amountInvalid={amountInvalid}
                  amountInvalidText={amountInvalidText}
                  setAmountInvalidText={setAmountInvalidText}
                  setAmountInvalid={setAmountInvalid}
                  handleClose={handleWalletLoadingModelClose}
                  clientData={clientData}
                  selectedIndex={selectedIndex}
                  onKeyPress={onKeyPress}
                  setAmount={(value) => setAmount(value)}
                  loading={loading}
                  onProceed={() => onWalletLoadingProceed()}
                />

                {/* send kyc link model */}
                <SendKycLinkModel
                  showModel={showModel}
                  handleClose={() => setShowModel(false)}
                  disable={disable}
                  linkErrors={linkErrors}
                  profileData={profileData}
                  onSubmit={(value) => {
                    setDisable(true);
                    sendKycLink(value);
                    eventLog({
                      type: "send_kyc_link",
                      action: {
                        trigger: "true",
                      },
                    });
                  }}
                />
                {/* client consent model */}
                <ClientOTPModelV2
                  openModel={showOtpModel}
                  setOtp={(value) => setClientOTP(value)}
                  loading={clientConsentModelLoader}
                  onSubmit={() => {
                    setClientConsentModelLoader(true);
                    verifyOTP();
                  }}
                  handleClose={() => {
                    setShowOtpModel(false);
                  }}
                  hideHeader={true}
                  headerTitle="OTP Verification for Terms & Conditions"
                />

                {/* client switch plan consent modal */}
                {showSwitchPlanRequestModal ? (
                  <ClientSwitchPlanModal
                    openModel={showSwitchPlanRequestModal}
                    handleClose={() => {
                      setShowSwitchPlanRequestModal(false);
                    }}
                    closeAndUpdate={() => {
                      setShowSwitchPlanRequestModal(false);
                      fetchClientList(page == 0 ? 1 : page, "");
                    }}
                    clientData={switchPlanClient}
                  />
                ) : (
                  <></>
                )}

                {/* full screen loader */}
                {loader ? <FullScreenLoader /> : <div />}
              </div>
            )}
          </div>

          {/* toast container */}
          <SampleToast />
        </div>
      )}
    </div>
  );
}));

export default ClientList;
