import { API } from "aws-amplify";
import { ClientlistSnapshot } from "../../../models";
import * as Types from "../api.types";

/**
 * Manages all requests to the API.
 */
export class Client_API {
  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  dataConverter = (raw: any) => {
    return {
      uuid: raw.uuid,
      name: raw.name === undefined || raw.name === null ? "" : raw.name,
      mobile: raw.mobile === undefined ? "" : raw.mobile,
      investor_id : raw.investor_id === undefined ? "" : raw.investor_id,
      available_funds: raw.balance === undefined ? "" : String(raw.balance),
      total_lent_amount : raw.total_lent_amount === undefined ?  "0"  : String(parseFloat(raw.total_lent_amount)),
      total_repaid_amount : raw.total_repaid_amount === undefined ? "0" : String(parseFloat(raw.total_repaid_amount)),
      locked_funds: raw.locked === undefined ? "" : String(raw.locked),
      invested_amount:
        raw.total_invested === undefined ? "0" :
          raw.freedom_plan_invested_amount === undefined ? String(raw.total_invested) :
            String(parseFloat(raw.total_invested) + parseFloat(raw.freedom_plan_invested_amount)),
      received_amount:
        raw.received_amount === undefined
          ? "0"
          : parseFloat(raw.received_amount),
      withdrawable:
        raw.withdrawable === undefined ? "0" : parseFloat(raw.withdrawable),
      freedom_plan_withdrawable:
        raw.freedom_plan_withdrawable === undefined
          ? "0"
          : parseFloat(raw.freedom_plan_withdrawable),
      freedom_plan_invested_amount:
        raw.freedom_plan_invested_amount === undefined
          ? "0"
          : parseFloat(raw.freedom_plan_invested_amount),
      kyc_status: raw.kyc_status === undefined ? 0 : raw.kyc_status,
      tc_status: raw.tc_status === undefined ? 0 : raw.tc_status,
      email: raw.email === undefined ? "" : raw.email,
      consent_status: raw.consent_status === undefined ? 0 : raw.consent_status,
      daily_growth:
        raw.daily_growth === undefined ? "" : String(raw.daily_growth),
      overall_fund:
        raw.overall_fund === undefined ? "" : String(raw.overall_fund),
      aum:
        raw.aum === undefined || raw.aum === null ? "0" : parseFloat(raw.aum),
      action:
        raw.kyc_status === undefined
          ? "Fix KYC"
          : raw.kyc_status === 1
            ? "Invest"
            : raw.kyc_status === 0
              ? "Fix KYC"
              : "Invest",
      net_worth: raw.is_net_worth === undefined ? 0 : raw.is_net_worth,
      remarks:
        raw.kyc_status == undefined || raw.is_matured == undefined
          ? "-"
          : raw.kyc_status == 3
            ? "Rejected"
            : raw.tc_status == 0
              ? "Incomplete."
              : raw.tc_status == 1
                ? "T&C Acceptance Pending"
                : raw.kyc_status == 1 && raw.tc_status == 2
                  ? "KYC verification in-progress."
                  : raw.kyc_status == 2 && raw.is_matured == 0
                    ? "Verified."
                    : raw.kyc_status == 2 && raw.is_matured == 1
                      ? "Funds will be maturing within the next 7 days."
                      : "-",
      remarks_color:
        raw.kyc_status == undefined || raw.is_matured == undefined
          ? ""
          : raw.kyc_status == 3
            ? "#DE2424"
            : raw.tc_status == 0
              ? "#E1A020"
              : raw.tc_status == 1
                ? "#069952"
                : raw.kyc_status == 1 && raw.tc_status == 2
                  ? "#E1A020"
                  : raw.kyc_status == 2 && raw.is_matured == 0
                    ? "#069952"
                    : raw.kyc_status == 2 && raw.is_matured == 1
                      ? "#3A86FF"
                      : "",
      invested_pools:
        raw.invested_pools === undefined ? [] : raw.invested_pools,
      withdrawn_amount:
        raw.withdrawn_amount === undefined
          ? 0
          : parseFloat(raw.withdrawn_amount),
      freedom_plan_locked_invested_amount:
        raw.freedom_plan_locked_invested_amount === undefined
          ? "0"
          : parseFloat(raw.freedom_plan_locked_invested_amount),
      freedom_plan_locked_earnings:
        raw.freedom_plan_locked_earnings === undefined
          ? "0"
          : parseFloat(raw.freedom_plan_locked_earnings),
      pan: raw.pan === undefined ? "" : raw.pan,
      nominee_details: raw.nominee_details ? raw.nominee_details : null,
      soa_consent: raw.soa_consent === undefined ? 0 : raw.soa_consent,
      switch_plan_amount:
        raw.switch_plan_amount === undefined
          ? 0
          : parseFloat(raw.switch_plan_amount),
    };
  };

  /**
   * @description This function is to get client list of ifa
   * @returns it returns user overall client list data.
   */
  async getClientlist(
    matured: any,
    min_investment: number = 0,
    is_kyc: false,
    page: number,
    pageSize: number,
    searchByName: String,
    sortingBy: String,
    isAsc: Boolean,
    isIdle: Boolean
  ): Promise<Types.GetClientlistResult> {
    //call the api try and catch
    try {
      const response: any = await API.get("baseurl", "/ifa/clients", {
        queryStringParameters: {
          isMatured: matured,
          min_investment: min_investment,
          is_kyc: is_kyc,
          page: page,
          page_size: pageSize,
          search_by_name: searchByName,
          sorting_by: sortingBy,
          is_asc: isAsc,
          is_idle: isIdle,
        },
        headers: {
          "Content-type": "application/json",
        },
      });
      const rawData = response.data;
      //console.log(rawData, "Raw Data");
      const convertedData: ClientlistSnapshot[] = rawData.map(
        this.dataConverter
      );
      //console.log("Converted", convertedData);
      return { kind: "ok", clientlist: convertedData, meta: response.meta };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to add new client with overall kyc
   * @returns it returns success and faliure
   */
  async addClientKycEntityDocument(
    data: any
  ): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa/clients/entity-documents",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description This function is to add new client using link with overall kyc
   * @returns it returns success and faliure
   */

  async selfClientKycEntityDocument(
    data: any
  ): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa-client/entity-documents",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to add new client with overall kyc
   * @returns it returns success and faliure
   */
  async addClientKyc(data: any): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/clients", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to add new client with overall kyc using ifa referral
   * @returns it returns success and faliure
   */
  async addClientKycByReferral(data: any): Promise<Types.GetClientKycResult> {
    // set param
    console.log("data" + data);
    let params = {
      body: data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa-client/referral",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is for converting raw data into the actual data
   * @param raw
   * @returns it return the actual data that can be displayed
   */
  convertClientData = (raw: any) => {
    return {
      // profile data
      profile_mobile: raw.mobile === null ? "" : raw.mobile,
      profile_name: raw.name === null ? "" : raw.name,
      profile_email: raw.email === null ? "" : raw.email,
      profile_father_name: raw.father_name === null ? "" : raw.father_name,
      profile_dob: raw.dob === null ? "" : raw.dob,
      profile_permanent_address:
        raw.permanent_address === null ? "" : raw.permanent_address,
      profile_pincode: raw.pincode === null ? "" : raw.pincode,
      profile_gender: raw.gender === null ? "" : raw.gender,
      profile_selfie_image: raw.selfie === null ? "" : raw.selfie,

      // document data
      document_pan_number:
        raw.documents.pan_number === null ? "" : raw.documents.pan_number,
      document_pan_image:
        raw.documents.pan_image === null ? "" : raw.documents.pan_image,
      document_address_front_image:
        raw.documents.address_front_image === null
          ? ""
          : raw.documents.address_front_image,
      document_address_back_image:
        raw.documents.address_back_image === null
          ? ""
          : raw.documents.address_back_image,
      document_net_worth_image:
        raw.documents.net_worth_image === null
          ? ""
          : raw.documents.net_worth_image,
      document_address_type:
        raw.documents.address_type == undefined
          ? 0
          : raw.documents.address_type,
      document_address_number:
        raw.documents.address_number == undefined
          ? ""
          : raw.documents.address_number,
      document_pan_name:
        raw.documents.pan_name == undefined ? "" : raw.documents.pan_name,
      document_data:
        raw.documents.document_data == undefined
          ? {}
          : raw.documents.document_data,

      // bank data
      bank_account_number:
        raw.bank.account_number === null ? "" : raw.bank.account_number,
      bank_account_holder_name:
        raw.bank.account_name === null ? "" : raw.bank.account_name,
      bank_ifsc_code: raw.bank.ifsc_code === null ? "" : raw.bank.ifsc_code,
      bank_cancel_cheque:
        raw.bank.cancel_cheque === null ? "" : raw.bank.cancel_cheque,
      bank_data: raw.bank.bank_data == undefined ? {} : raw.bank.bank_data,

      // nominee data
      nominee_full_name: raw.nominee.name === null ? "" : raw.nominee.name,
      nominee_phone_number:
        raw.nominee.phone_number === null ? "" : raw.nominee.phone_number,
      nominee_email_id:
        raw.nominee.email_id === null ? "" : raw.nominee.email_id,
      nominee_pan_number:
        raw.nominee.pan_number === null ? "" : raw.nominee.pan_number,
      nominee_dob: raw.nominee.dob === null ? "" : raw.nominee.dob,
      nominee_relationship:
        raw.nominee.relationship === null ? "" : raw.nominee.relationship,
    };
  };

  /**
   * @description This function is to fetch client kyc by uuid
   * @returns it returns the clients kyc overall data
   */
  async fetchClientKyc(uuid: any): Promise<Types.GetClientKycResult> {
    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/ifa/clients/${uuid}`,
        {}
      );
      const rawData = response.data;
      const convertedData: any = this.convertClientData(rawData);
      return { kind: "ok", data: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to update client kyc
   * @returns it returns success and faliure
   */
  async updateClientKyc(
    data: any,
    uuid: any
  ): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.put(
        "baseurl",
        `/ifa/clients/${uuid}`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.errors };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for fetching the client investment
   * @param data
   * @returns it returns the client overall investment data
   */
  async fetchClientInvestments(
    data: any,
    status: any
  ): Promise<Types.GetClientInvestmentsResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/${data.client_id}/investments?status=${status}`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get client repayment
   * @param data
   * @returns it returns repayment data
   */
  async getClientRepayment(data: any): Promise<Types.GetClientRepaymentResult> {
    // convert data
    const convertRepaymentData = (raw: any) => {
      return {
        id: raw.repayment_id,
        date:
          raw.repayment_due_date === undefined ? "" : raw.repayment_due_date,
        principal_due:
          raw.repayment_principal_amount === undefined
            ? "0"
            : parseFloat(raw.repayment_principal_amount),
        interest_due:
          raw.repayment_interest_amount === null
            ? "0"
            : parseFloat(raw.repayment_interest_amount),
        status:
          raw.repayment_status === 0
            ? "Upcoming"
            : raw.repayment_status === 1
              ? "Received"
              : raw.repayment_status === 2
                ? "Short"
                : "Overdue",
        repaid_amount:
          raw.repaid_amount === undefined ? "0" : parseFloat(raw.repaid_amount),
      };
    };

    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/${data.client_id}/investments/${data.investment_id}/repayments`,
        params
      );
      const rawData = response.data;
      const convertedData = rawData.map(convertRepaymentData);
      return { kind: "ok", data: convertedData };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for updating the client net worth
   * @param data
   * @returns it returns success or faliure
   */

  async updateClientNetWorth(data: any): Promise<Types.GetClientNetWorth> {
    // set param
    let params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/ifa/clients/uploads",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description this function is for verifying referral code of ifa
   * @param referral_code
   * @returns it returns success or faliure
   */
  async checkReferralCode(
    referral_code: any
  ): Promise<Types.GetIFAReferralCode> {
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa-referral-code/${referral_code}`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for fetching the client investment summary
   * @param data
   * @returns it returns success or failure
   *
   */
  async fetchClientSummary(
    data: any
  ): Promise<Types.GetClientInvestmentsResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/${data.client_id}/overview`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for fetching the client investment consent data
   * @param data
   * @returns it returns client investment consent details
   *
   */
  async fetchInvestmentConsentData(
    data: any
  ): Promise<Types.GetInvestmentConsentResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/investment-consent/${data.consent_uuid}`,
        {}
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get client consent through otp
   * @returns it returns success and faliure
   */
  async getClientConsent(data: any): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", "/ifa/otp", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get client consent email trigger
   * @returns it returns success and faliure
   */
  async getClientConsentEmail(data: any): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/tc/email`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to verify client consent
   * @returns it returns success and faliure
   */
  async verifyClientConsent(data: any): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.put("baseurl", "/ifa/otp", params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to verify client consent
   * @returns it returns success and faliure
   */
  async fetchClientTransactionHistory(
    data: any,
    page: any
  ): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/v2/ifa/clients/transactions?page=${page ? page : 1}`,
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to fetch growth plan investments
   * @returns array of growth plan investments
   **/
  async fetchClientGrowthPlanInvestments(
    data: any
  ): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/v2/ifa/clients/growth_plan_investments",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to fetch freedom plan earnings of client
   * @returns array of freedom plan earnings
   **/
  async fetchFreedomPlanEarnings(data: any): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/v2/ifa/clients/freedom_plan_earnings",
        params
      );
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description This function is to get net worth certificate
   * @returns it returns success and faliure
   */
  async getNetWorthCertificate(data: any): Promise<Types.GetClientKycResult> {
    // set param
    let params = {
      queryStringParameters: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        "/v2/ifa/clients/net_worth_certificate",
        params
      );
      console.log(response);
      return { kind: "ok", data: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description this function is for recording the client consent for investment on their behalf
   * @param data
   * @returns it returns success or failure
   *
   */
  async postClientInvestmentConsent(
    data: any
  ): Promise<Types.PostClientInvestmentsConsent> {
    // set param
    let params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/ifa/clients/${data.client_id}/investment-consent`,
        params
      );
      return { kind: "ok", message: response.message };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }
  /**
   * @description This function is to get client nominee details through mobile
   * @returns it returns success and faliure
   */
  async getNomineeDetails(data: any): Promise<Types.GetClientData> {
    // set param
    let params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post("baseurl", `/ifa/tc`, params);
      return { kind: "ok", data: response.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

  /**
   * @description method to fetch list of client investment certificate
   * @param client_id
   * @returns success or failure
   */
  
  

  async getBorrowerMappingPDFs(
    data: any
  ): Promise<Types.GetBorrowerMappingList> {
    console.log(data," state of applicatiopn ")
    let params = {
      body: data,
    };
    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        `/v2/ifa/get_pdf`,
        params
      );
      return { kind: "ok", data: response.data, meta: response.meta };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }


  /**
   * @description method to fetch list of client investment certificate
   * @param client_id
   * @returns success or failure
   */
  async getClientInvestmentCertificateList(
    client_id: any,
    page: any,
    pageSize: any,
    search: any,
    sortingBy: any,
    isAsc: any
  ): Promise<Types.GetClientInvestmentCertificateResult> {
    //call the api try and catch
    try {
      const response: any = await API.get(
        "baseurl",
        `/ifa/clients/${client_id}/investment-certificate`,
        {
          queryStringParameters: {
            page: page,
            pageSize: pageSize,
            search: search == "" ? null : search,
            sorting_by: sortingBy,
            is_asc: isAsc,
          },
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return { kind: "ok", data: response.data, meta: response.meta };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return { kind: "bad-data", error: err.response.data.message };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }


  
  /**
   * @description This function is to get tranch data
   * @returns it returns success and faliure
   */
  async getTranchReport(data: any): Promise<Types.GetTranchData> {
    console.log("hello this is body", data)
    // set param
    let params = {
      body: data,
    };

    //call the api try and catch
    try {
      const response: any = await API.post(
        "baseurl",
        "/users/tranch",
        params
      );
      console.log(response);
      return { kind: "ok", data: response.data.data };
    } catch (err: any) {
      if (err) {
        if (err.response === undefined) {
          return {
            kind: "bad-data",
            error:
              "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in",
          };
        } else {
          if (err.response.data.code === 422) {
            return {
              kind: "bad-data",
              error: err.response.data.errors,
            };
          }
          return { kind: "bad-data", error: err.response.data.message };
        }
      }
      return { kind: "bad-data", error: err.response.data.message };
    }
  }

}
