import React, { useEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import "./client-overview.css";
import Loader from "react-loader-spinner";
import { Client_API } from "../../services/api/Clients/clients-api";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import DoughnutChart from "../charts/doughnut-chart/doughnut-chart";
import useWindowDimensions from "../get-window-dimension/get-window-dimension";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  burningMoney,
  investableAmountIcon,
  totalWithdrawalsIcon,
  overallEarningsIcon,
  portfolioValueIcon,
} from "../../assets/images/index";
import WalletLoadingModel from "../wallet-loading-model/wallet-loading-model";
import ClientPortfolioModel from "../clientPortfolioModel/clientPortfolioModel";
import ClientOverallEarningsModel from "../clientOverallEarningsModel/clientOverallEarningsModel";
import { WalletApi, WithdrawalApi } from "../../services/api";
import { eventLog } from "../../utils/firebase";
import WithdrawalModel from "../withdrawal-model/withdrawal-model";
import CustomTooltip from "../tool-tip/tool-tip";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import config from "../../config/settings";
import ClientSwitchPlanModal from "../client-switch-plan-modal/client-switch-plan-modal";
import AdditionalInfo from "../additional_info/additional_info";

const defaultChartData = {
  growth_plan_invested_amount: {
    name: "Growth Plan Invested Amount",
    value: 0,
    color: "#064586",
  },
  freedom_plan_invested_amount: {
    name: "Freedom Plan Invested Amount",
    value: 0,
    color: "#479AEF",
  },
  left_invested_amount: {
    name: "Left Invested Amount",
    value: 0,
    color: "#E6F2FF",
  },
};

const maxInvestementAmountBeforeNetWorth = 1000000;
const maxInvestementAmountAfterNetWorth = 5000000;

export default function ClientOverview(props) {
  const [render, setRender] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [maxInvestmentAmount, setMaxInvestmentAmount] = useState(
    maxInvestementAmountBeforeNetWorth
  );
  const dimensions = props.dimensions;
  const walletApi = new WalletApi();
  const withdrawalApi = new WithdrawalApi();
  const [freedomPlanEarnings, setFreedomPlanEarnings] = useState(0);
  const [totalLentAmount, setTotalLentAmount] = useState(0);
  const [totalRepaidAmount, setTotalRepaidAmount] = useState(0);
  const [freedomPlanLockedEarnings, setFreedomPlanLockedEarnings] = useState(0);
  const [freedomPlanLockedInvestedAmount, setFreedomPlanLockedInvestedAmount] =
    useState(0);
  const [totalInvestedAmount, setTotalInvestedAmount] = useState(0);
  const [portfolioAmount, setPortfolioAmount] = useState(0);
  const [overallAmount, setOverallAmount] = useState(0);
  const [totalWithdrawnAmount, setTotalWithdrawnAmount] = useState(0);
  const [growthPlanInvestedAmount, setGrowthPlanInvestedAmount] = useState(0);
  const [availableToInvest, setAvailableToInvest] = useState(0);
  const [totalWithdrawalAmount, setTotalWithdrawalAmount] = useState(0);
  const [overallReturn, setOverallReturn] = useState(0);
  const [chartData, setChartData] = useState(defaultChartData);
  const [openWithdrawalRequestModel, setOpenWithdrawalRequestModel] =
    useState(false);
  const [openWalletLoadingModel, setOpenWalletLoadingModel] = useState(false);
  const [openPortfolioModel, setOpenPortfolioModel] = useState(false);
  const [openOverallEarningsModel, setOpenOverallEarningsModel] =
    useState(false);
  const [amountInvalid, setAmountInvalid] = useState(false);
  const [amountInvalidText, setAmountInvalidText] = useState("");
  const [amount, setAmount] = useState(0);
  const [locked, setLocked] = useState(0);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("User");
  const navigate = useHistory();
  const [showSwitchPlanRequestModal, setShowSwitchPlanRequestModal] =
    useState(false);
  const [switchPlanClient, setSwitchPlanClient] = useState({});
  const freedom_plan_withdrawable =
    parseFloat(props.overviewData.freedom_plan_invested_amount) +
    parseFloat(props.overviewData.freedom_plan_earnings);

  useEffect(() => {
    fetchClientOverview();
    setRender(true);
  }, [props, render]);

  // amount key press handler
  const onKeyPress = (e) => {
    const re = /[^0-9\.]/g;
    if (re.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleButtonClick = () => {
    setIsPopupOpen(true);
  }

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const fetchClientOverview = async () => {
    var tempMaxInvestmentValue = maxInvestementAmountBeforeNetWorth;

    if (props.overviewData.is_net_worth == 2) {
      tempMaxInvestmentValue = maxInvestementAmountAfterNetWorth;
    }
    setLocked(convertAmount(props.overviewData.locked));
    setTotalInvestedAmount(props.overviewData.total_invested_amount);
    // calculating overall active and inprocess investments
    let totalSum =
      props.overviewData.active_invested_amount.earnings_at_maturity +
      props.overviewData.active_invested_amount.monthly_earnings +
      props.overviewData.active_invested_amount.freedom_plan +
      props.overviewData.inprocess_invested_amount.earnings_at_maturity +
      props.overviewData.inprocess_invested_amount.monthly_earnings +
      props.overviewData.inprocess_invested_amount.freedom_plan;
    setPortfolioAmount(totalSum);
    // calculating overall freedom and growth plan earnings till date
    let sumOverallEarnings =
      props.overviewData.overall_earnings.earnings_at_maturity +
      props.overviewData.overall_earnings.monthly_earnings +
      props.overviewData.overall_earnings.freedom_plan;
    setOverallAmount(sumOverallEarnings);
    setOverallAmount(sumOverallEarnings);
    setGrowthPlanInvestedAmount(props.overviewData.growth_plan_invested_amount);
    setChartData({
      ...chartData,
      growth_plan_invested_amount: {
        ...chartData["growth_plan_invested_amount"],
        value: props.overviewData.growth_plan_invested_amount,
      },
      freedom_plan_invested_amount: {
        ...chartData["freedom_plan_invested_amount"],
        value: props.overviewData.freedom_plan_invested_amount,
      },
      left_invested_amount: {
        ...chartData["left_invested_amount"],
        value:
          tempMaxInvestmentValue -
          (props.overviewData.growth_plan_invested_amount +
            props.overviewData.freedom_plan_invested_amount),
      },
    });

    setMaxInvestmentAmount(tempMaxInvestmentValue);
    setAvailableToInvest(props.overviewData.available_to_invest);
    setTotalWithdrawnAmount(props.overviewData.total_withdrawn_amount);
    setTotalWithdrawalAmount(
      props.overviewData.total_available_funds_to_withdraw
    );
    setOverallReturn(props.overviewData.overall_return);
    setFreedomPlanEarnings(props.overviewData.freedom_plan_earnings);
    setTotalLentAmount(props.overviewData.total_lent_amount);
    setTotalRepaidAmount(props.overviewData.total_repaid_amount);
    setFreedomPlanLockedEarnings(
      props.overviewData.freedom_plan_locked_earnings
    );
    setFreedomPlanLockedInvestedAmount(
      props.overviewData.freedom_plan_locked_invested_amount
    );
    setName(props.overviewData.name);
  };

  // on wallet loading proceed click
  const onWalletLoadingProceed = async () => {
    if (!isNaN(parseFloat(amount))) {
      if (amount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (amount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (amount % 1000 != 0) {
        errorToast("Amount should be in multiple of 1000.");
      } else {
        setLoading(true);
        let payload = {
          user_id: props.clientUuid,
          amount: parseFloat(amount),
        };
        await walletApi.postWalletRequest(payload).then((res) => {
          if (res.kind === "ok") {
            successToast("Wallet loading request sent successfully.");
            setLoading(false);
            setOpenWalletLoadingModel(false);
            eventLog({
              type: "wallet_request",
              action: {
                trigger: "true",
              },
            });
          } else {
            eventLog({
              type: "wallet_request",
              action: {
                trigger: "false",
              },
            });
            errorToast(res.error);
            setLoading(false);
            setOpenWalletLoadingModel(false);
          }
        });
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  // on proceed click
  const onWithdrawalRequestProceed = async () => {
    const withdrawableLimit = config.withdrawable_limit;
    if (!isNaN(parseFloat(amount))) {
      if (amount == 0) {
        errorToast("Amount cannot be empty.");
      } else if (amount > parseFloat(totalWithdrawalAmount)) {
        errorToast("Amount cannot be greater than available balance.");
      } else if (amount < 0) {
        errorToast("Amount cannot be  negative.");
      } else if (amount < 1) {
        errorToast("The minimum amount that can be withdrawn is ₹ 1.");
      } else if (amount > withdrawableLimit) {
        errorToast(
          `Amount cannot be greater than ₹ ${convertAmount(withdrawableLimit)}`
        );
      } else {
        setLoading(true);
        let payload = {
          user_id: props.clientUuid,
          amount: parseFloat(amount),
          is_ifa: true,
        };
        await withdrawalApi.updateWithdrawalRequest(payload).then((res) => {
          if (res.kind === "ok") {
            setTimeout(() => {
              successToast("Email has been sent to client");
              setLoading(false);
              setOpenWithdrawalRequestModel(false);
              eventLog({
                type: "withdrawal_request",
                action: {
                  trigger: "true",
                },
              });
            }, 3000);
          } else {
            eventLog({
              type: "withdrawal_request",
              action: {
                trigger: "false",
              },
            });
            errorToast(res.error);
            setLoading(false);
          }
        });
      }
    } else {
      errorToast("Please enter a valid amount.");
    }
  };

  if (render == false) {
    return (
      <div className="client_overview_loader_div">
        <Loader type="TailSpin" color="#414141" height={80} width={80} />
      </div>
    );
  }
  return (
    <div className="client_overview_container">
      {/* all action buttons */}
      <div className="client_overview_actions">
        <div className="client_overview_actions_header"> Actions </div>
        <div className="client_overview_action_buttons">
          {(props.overviewData.consent_status != 2 ||
            props.overviewData.soa_consent != 2) && (
            <button
              className="client_overview_button"
              onClick={() => {
                props.setOpenConsentRequestModel(true);
                eventLog({
                  type: "click_wallet_loading_request",
                  action: {
                    trigger: "false",
                  },
                });
              }}
            >
              <div className="client_overview_button_text">
                {props.overviewData.consent_status == 1 ||
                props.overviewData.soa_consent == 1
                  ? "Resend Consent Request"
                  : "Send Consent Request"}
              </div>
            </button>
          )}

          {/* {freedom_plan_withdrawable >= 10000 ? (
            <button
              className="client_overview_button"
              onClick={() => {
                setSwitchPlanClient({
                  ...props.overviewData,
                  freedom_plan_withdrawable,
                  uuid: props.param.state.uuid,
                  name: props.param.state.name,
                });
                setShowSwitchPlanRequestModal(true);
              }}
            >
              <div className="client_overview_button_text">
                Send Switch Plan Request
              </div>
            </button>
          ) : (
            <></>
          )} */}

          <button
            className="client_overview_button"
            onClick={() => {
              setOpenWalletLoadingModel(true);
              eventLog({
                type: "click_wallet_loading_request",
                action: {
                  trigger: "false",
                },
              });
            }}
          >
            <div className="client_overview_button_text">
              Send Wallet Loading Request
            </div>
          </button>

          <button
            className={
              totalWithdrawalAmount != 0
                ? "client_overview_button"
                : "client_overview_button_disabled"
            }
            disabled={totalWithdrawalAmount != 0 ? false : true}
            onClick={() => {
              //setOpenWithdrawalRequestModel(true);
              handleButtonClick()
              eventLog({
                type: "click_withdrawal_request",
                action: {
                  trigger: "true",
                },
              });
            }}
          >
            <div
              className={
                totalWithdrawalAmount != 0
                  ? "client_overview_button_text"
                  : "client_overview_button_text_disabled"
              }
            >
              Send Withdrawal Request
            </div>
          </button>
          <button
            className={
              props.overviewData.soa_consent === 2
                ? "client_overview_button"
                : "client_overview_button_disabled"
            }
            disabled={props.overviewData.soa_consent === 2 ? false : true}
            onClick={() => {
              eventLog({
                type: "view_investment_certificates",
                action: {
                  trigger: "true",
                },
              });
              navigate.push("/clients/investment-certificate", {
                uuid: props.param.state.uuid,
                name: props.param.state.name,
                net_worth: props.param.state.net_worth,
                soa_consent: props.overviewData.soa_consent,
              });
            }}
          >
            <div
              className={
                props.overviewData.soa_consent === 2
                  ? "client_overview_button_text"
                  : "client_overview_button_text_disabled"
              }
            >
              Investment Certificates
            </div>
          </button>
          <button
            className={
              props.overviewData.wallet_transactions != 0
                ? "client_overview_button"
                : "client_overview_button_disabled"
            }
            disabled={
              props.overviewData.wallet_transactions != 0 ? false : true
            }
            onClick={() => {
              props.setOpenTransactionHistoryModel(true);
              eventLog({
                type: "view_transaction_history",
                action: {
                  trigger: "true",
                },
              });
            }}
          >
            <div
              className={
                props.overviewData.wallet_transactions != 0
                  ? "client_overview_button_text"
                  : "client_overview_button_text_disabled"
              }
            >
              Transaction History
            </div>
          </button>
        </div>
      </div>
      {/* all values for already invested client */}
      <div className="client_overview_investment_details">
          <div className="investment_detail_card">
            <div className="investment_detail_info">
              Total Lent Amount (as on 16-10-2024)
            </div>
            <div className="investment_detail_content">
              ₹ {convertAmount(totalLentAmount || 0)}
            </div>
          </div>
          <div className="investment_detail_card">
            <div className="investment_detail_info">
              Total Repaid Amount after 16-10 2024
            </div>
            <div className="investment_detail_content">
            ₹ {convertAmount(totalRepaidAmount || 0)}
            </div>
          </div>
        </div>
        <div className="disclaimer-container">
          <InfoOutlinedIcon className="info-icon" />
          <span className="disclaimer-text">
            <span className="bold-text" style={{fontWeight: "600", opacity: "0.9", fontSize: "16px", color: "#404040"}}>Disclaimer :</span> The data shown is based on the records maintained by RNVP Technologies Limited, and the accuracy and completeness of the information are reliant on the records of RNVP Technologies Limited.
          </span>
        </div>
      {isPopupOpen && (
        <div className="popup">
          <div className="popup-content">
            <h5>To comply with the latest regulatory changes in NBFC-P2P dated 17 August 2024, withdrawals have been halted across all accounts. All maturities, including those under the Freedom Plan, will now be processed based on borrower mapping and repaid as per borrower repayments.</h5>
              <button className="popup-button" onClick={closePopup}>Close</button>
          </div>
        </div>
	    )}
    </div>
  );
}
