import React, { useEffect, useState } from "react";
import "./overview.css";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react-lite";
import { close } from "../../assets/images/index";
import { Redirect, useHistory } from "react-router-dom";
import { User_API } from "../../services/api";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import InfoIcon from "@mui/icons-material/Info";
import Modal from "@mui/material/Modal";
import { kycStatus_API } from "../../services/api/kycstatus/kycstatus-api";
import {
  LabelWithSortComponent,
  OverviewAumModal,
} from "../../components/index";
// import HideIFA from "../HideIFA/hideIFA";
import { eventLog } from "../../utils/firebase";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  errorToast,
  successToast,
  getFormattedDate,
  convertAmount,
  sorting,
} from "../../utils/helper";
import {
  ContentLoadingLoader,
  FullScreenLoader,
  SampleToast,
  CustomTooltip,
} from "../../components";
import moment from "moment";
import { set } from "mobx";

const tableContainer = {
  marginTop: "20px",
  width: "95%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "scroll",
};

const tableHeader = {
  backgroundColor: "#ececec",
  fontWeight: "600",
  fontSize: 15,
  fontFamily: "Asap",
  color: "#1f1f1f",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
};

const tableRow = {
  fontSize: 13,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  borderRight: "1px solid #c7c7c7",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const Overview = React.memo(observer(function Overview(props)  {
  const [isLogin, setIsLogin] = useState();
  const [openModel, setOpenModel] = useState(false);
  const [openMaturityModel, setOpenMaturityModel] = useState(false);
  const [ifaData, setIfaData] = useState([]);
  const pageSize = 10;
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [aumData, setAumData] = useState([]);
  const [maturityData, setMaturityData] = useState([]);
  const [fullLoader, setFullLoader] = useState(false);
  const [loginRender, setLoginRender] = useState(false);
  const [loader, setLoader] = useState(false);
  const [render, setRender] = useState(false);
  const navigate = useHistory();
  const api = new User_API();
  const [pageNumber, setPageNumber] = useState(1);
  const asc_label_sort = {
    due_date: true,
    client_name: false,
    amount: false,
  };
  const [asc, setAsc] = useState(asc_label_sort);
  const [selected, setSelected] = useState("due_date");
  const [selectedType, setSelectedType] = useState("timestamp");
  const [sorting_order,setSortingOrder] = useState("ASC");
  


  // check if user login or not
  useEffect(() => {
    props.showCommonHeader(true);
    let checkLogin = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        let status = await localStorage.getItem("kyc_status");
        if (status != 2) {
          setIsLogin(false);
        }
        else
        {
          fetchIfakycStatus();
        }
          
              } catch {
        setIsLogin(false);
        errorToast(
                "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
              );
      }
    };
    return checkLogin();
    // eslint-disable-next-line
  }, []);


  useEffect(() => {
    if(openMaturityModel)
      {
        getMaturityData(pageSize, page,selected,sorting_order);
      }
      
  }, [sorting_order]);

  

  // fetch ifa kyc status
  const fetchIfakycStatus = () => {
    setLoader(true);
    // const api = new kycStatus_API();

    // props.showCommonSidebar(true);
    // setLoader(false);
    // fetchOverview();
    // setIsLogin(true);
    // setLoginRender(true);

    // fetch data
    // api
    //   .getkycStatusData()
    //   .then((res) => {
    //     if (res.kind === "ok") {

    //     } else {
    //       setLoader(false);
    //       navigate.push("/");
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     setLoader(false);
    //     errorToast(
    //       "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
    //     );
    //   });
    props.showCommonSidebar(true);
    setLoader(false);
    fetchOverview();
    setIsLogin(true);
    setLoginRender(true);
  };

  // get the data
  const fetchOverview = () => {
    api.getUserSummary().then((res) => {
      if (res.kind === "ok") {
        setIfaData(res.data);
        setRender(true);
        eventLog({
          type: "overview",
          action: {
            trigger: "true",
          },
        });
      } else {
        setRender(true);
        eventLog({
          type: "overview",
          action: {
            trigger: "false",
          },
        });
        errorToast(res.error);
      }
    });
  };

  // close model
  const handleClose = () => {
    setOpenModel(false);
  };

  // fetch aum data
  const fetchAumData = () => {
    // setFullLoader(true);
    // getAumData(pageSize, 1);
    setOpenModel(true);
  };



  // close maturity model
  const handleMaturityClose = () => {
    setPage(0);
    setOpenMaturityModel(false);
  };

  // fetch maturity data
  const fetchMaturityData = () => {

  setFullLoader(true);
  getMaturityData(pageSize, 1,selected,sorting_order);
  };

  // get maturity data
  const getMaturityData = async (pageSize, page,selected,sorting_order) => {
    console.log("selected",selected)
    await api
      .getUserInvestmentMaturity(
        pageSize,
        page,
        selected == "amount" ? "principal_amount" : "due_date",
        sorting_order,
      )
      .then((res) => {
        if (res.kind === "ok") {
          setFullLoader(false);
          setPagination(res.meta.pagination);
          setMaturityData(res.data);
          setOpenMaturityModel(true);
        } else {
          setFullLoader(false);
          errorToast(res.error);
        }
      });
  };

  // get maturity data
  const triggerMaturityEmailRequest = async (data) => {
    await api.sendMaturityEmail(data).then((res) => {
      if (res.kind === "ok") {
        setFullLoader(false);
        successToast(`Maturity email sent successfully to ${data.name}`);
      } else {
        setFullLoader(false);
        errorToast(res.error);
      }
    });
  };

  const idleAmountTrigger = () => {
    navigate.push("/clients/idle", {
      title: "idle",
    });

    eventLog({
      type: "idle_amount",
      action: {
        trigger: "true",
      },
    });
  };

  const TooltipOverviewLabel = ({ label, infoText }) => {
    return (
      <div className="overview_tooltip_grid">
        <div>
          <p className="overview_row_container_title">{label}</p>
        </div>
        <div>
          <CustomTooltip info={infoText}>
            <InfoIcon
              style={{
                fontSize: "17px",
                marginLeft: "5px",
                color: "#3A86FF",
                marginTop: "-2px",
                // border:"1px solid yellow"
              }}
            />
          </CustomTooltip>
        </div>
      </div>
    );
  };

  // if user is not login then redirect to login page
  if (isLogin === false) {
    return <Redirect to="/" />;
  }

  // if (hideIFA) {
  //   return <HideIFA />;
  // }

  const handleOnSortBy = () => {
    // getMaturityData(pageSize, pagination.current_page,selected,sorting_order);
  };



  return (
    <div>
      {loginRender === false ? (
        <div />
      ) : (
        <div className="overview_div_container">
          {render === false ? (
            <ContentLoadingLoader />
          ) : (
            <div className="overview_rest_div">
              {/* heading */}
              {/*<h1 className="overview_rest_div_heading">Overview</h1>*/}

              {/* column container 1 */}

              <div className="overview_border">
                <div className="overview_client_background">
                  <h1 className="overview_client_heading">Client Summary</h1>
                </div>
                <div className="overview_column_container">
                  {/* total number of clients */}
                  <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      <p className="overview_row_container_title">
                        Number of clients
                      </p>
                      <p className="overview_row_container_subtitle">
                        {ifaData !== undefined
                          ? ifaData.summary !== undefined
                            ? ifaData.summary.clients
                              ? ifaData.summary.clients
                              : "0"
                            : "0"
                          : "0"}
                      </p>
                    </div>
                  </div>

                  {/* total AUM */}
                  <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      {/* <TooltipOverviewLabel
                        label="Assets Under Management"
                        infoText="Total value of active investments excluding the earnings generated + In-process investments."
                      /> */}
                      <p className="overview_row_container_title">
                        Total Lent Amount (as on 16-10-2024)
                      </p>
                      <p className="overview_row_container_subtitle">
                        {(ifaData !== undefined
                          && ifaData.summary !== undefined && ifaData.summary.total_lent_amount != undefined)
                            ? `₹ ${convertAmount(ifaData.summary.total_lent_amount)}` : "₹ 0"}
                      </p>
                      {/* <p
                        className="overview_row_container_highlight"
                        onClick={fetchAumData}
                        style={{
                          display: ifaData
                            ? ifaData.summary
                              ? ifaData.summary.aum +
                                ifaData.summary.total_inprocess_amount !=
                                0
                                ? "flex"
                                : "none"
                              : "none"
                            : "none",
                          // display : "none"
                        }}
                      >
                        View Breakdown
                      </p> */}
                    </div>
                  </div>


                  {/* Idle Amount*/}
                  <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      {/* <TooltipOverviewLabel
                        label="Idle Funds"
                        infoText="Total amount available to either invest or withdraw."
                      /> */}
                      <p className="overview_row_container_title">
                        Total Repaid Amount after 16-10 2024
                      </p>
                      <p className="overview_row_container_subtitle">
                        {(ifaData !== undefined
                          && ifaData.summary !== undefined && ifaData.summary.total_repaid_amount != undefined)
                            ? `₹ ${convertAmount(ifaData.summary.total_repaid_amount)}` : "₹ 0"}
                      </p>
                      {/* <p
                        className="overview_row_container_highlight"
                        onClick={idleAmountTrigger}
                        style={{
                          display: ifaData
                            ? ifaData.summary
                              ? ifaData.summary.idle_amount
                                ? "flex"
                                : "none"
                              : "none"
                            : "none",
                        }}
                      >
                        View Breakdown
                      </p> */}
                    </div>
                  </div>

                  {/* <div className="overview_row_container">
                    <div className="overview_row_container_box">
                      <p className="overview_row_container_title">
                        Upcoming Maturity (Next 30 days)
                      </p>
                      <p className="overview_row_container_subtitle">
                        {ifaData !== undefined
                          ? ifaData.summary !== undefined
                            ? ifaData.summary.count_matured_user_list
                              ? ifaData.summary.count_matured_user_list
                              : "0"
                            : "0"
                          : "0"}
                      </p>
                      <p
                        className="overview_row_container_highlight"
                        onClick={fetchMaturityData}
                        style={{
                          display: ifaData
                            ? ifaData.summary
                              ? ifaData.summary.count_matured_user_list
                                ? "flex"
                                : "none"
                              : "none"
                            : "none",
                        }}
                      >
                        View Breakdown
                      </p>
                    </div>
                  </div> */}
                </div>
              </div>

              <br />

            </div>
          )}

          {/* full screen loader */}
          {fullLoader ? (
            <FullScreenLoader open={fullLoader ? true : false} />
          ) : (
            <div />
          )}

          {/* toast container */}
          <SampleToast />
        </div>
      )}

      {/* show model */}
      <Modal
        open={openModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "scroll",
        }}
      >
        <OverviewAumModal handleClose={handleClose} data={ifaData.summary} />
      </Modal>

      {/* show maturity model */}
      <Modal
        open={openMaturityModel}
        onClose={handleMaturityClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          overflow: "scroll",
        }}
      >
        <div className="overview-model-div">
          {/* header */}
          <div className="overview-model-header-div">
            <h1 className="overview-model-header-text">
              Upcoming Maturity in Next 30 Days
            </h1>
            <div>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setPage(0);
                  setOpenMaturityModel(false);
                }}
                src={close}
              />
            </div>
          </div>

          {/* table content */}
          <TableContainer style={tableContainer}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={tableHeader}>
                    S.No
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Name of the Investor
                  </TableCell>
                  {/* <TableCell align="left" style={tableHeader}>
                    Mobile Number
                  </TableCell> */}
                  <TableCell align="left" style={tableHeader}>
                    <LabelWithSortComponent
                      label="Amount"
                      id="amount"
                      type="number"
                      asc={asc}
                      selected={selected}
                      setAsc={setAsc}
                      setSelected={setSelected}
                      setSelectedType={setSelectedType}
                      onClick={handleOnSortBy}
                      setSortingOrder = {setSortingOrder}
                    />
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    Plan Name
                  </TableCell>
                  <TableCell align="left" style={tableHeader}>
                    <LabelWithSortComponent
                      label="Maturity Date"
                      type="timestamp"
                      asc={asc}
                      id="due_date"
                      selected={selected}
                      setAsc={setAsc}
                      setSelected={setSelected}
                      setSelectedType={setSelectedType}
                      onClick={handleOnSortBy}
                      setSortingOrder = {setSortingOrder}
                    />
                  </TableCell>
                </TableRow>
               
              </TableHead>

              <TableBody>
                {maturityData.map((row, index) => {
                  return (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                      }}
                    >
                      <TableCell style={tableRow} align="left">
                        {(pagination.current_page - 1) * rowsPerPage +
                          index +
                          1}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        <p
                          className="clientlist_client_name"
                          style={{
                            color: "#3A86FF",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (row.kyc_status == 2) {
                              navigate.push("/clients/investments", {
                                name: row.name,
                                uuid: row.uuid,
                                net_worth: row.net_worth,
                              });
                              eventLog({
                                type: "client_investments",
                                action: {
                                  trigger: "true",
                                },
                              });
                            } else {
                              navigate.push("/clients/kyc", {
                                uuid: row.uuid,
                              });
                            }
                          }}
                        >
                          {row.name}
                        </p>
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        ₹ {convertAmount(row.amount.toFixed(2))}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {row.title}
                      </TableCell>
                      <TableCell style={tableRow} align="left">
                        {/* conole.log() */}
                        {moment(row.due_date).format("DD-MM-YYYY")}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            
            </Table>
            <p>
              Note :The Maturity Amount will be added to the wallet on the
              Maturity Date
            </p>
            {/* If no data available */}
            {maturityData.length === 0 ? (
              <div className="no_row_found_div">
                <FindInPageIcon
                  style={{
                    fontSize: "100px",
                    color: "#555555",
                  }}
                />
                <p className="no_row_found">No row found</p>
              </div>
            ) : (
              <div />
            )}
          </TableContainer>
          {/* Pagination */}
          <div className="overview_pagination_div">
            {/* Previous */}
            <ArrowBackIosIcon
              style={{
                fontSize: "14px",
                color: pagination.current_page == 1 ? "#b8b8b8" : "#3d3d3d",
                marginRight: "20px",
                cursor: pagination.current_page == 1 ? "default" : "pointer",
              }}
              onClick={() => {
                if (pagination.current_page > 0) {
                  if (pagination.current_page !== 1) {
                    getMaturityData(pageSize, pagination.current_page - 1,selected,sorting_order);
                    setPage(pagination.current_page - 1);
                  }
                }
              }}
            />
            {/* Next */}
            <ArrowForwardIosIcon
              style={{
                fontSize: "14px",
                color: pagination.count == pageSize ? "#3d3d3d" : "#b8b8b8",
                marginRight: "30px",
                cursor: pagination.count == pageSize ? "pointer" : "default",
              }
            }
              onClick={() => {
                  
                if (pagination.count == pageSize) {
                  getMaturityData(pageSize, pagination.current_page + 1,selected,sorting_order);
                  setPage(pagination.current_page + 1);
          
                }
              }}
            />
          </div>

          {/* footer */}
          {/* <div className="overview-model-footer-div">
            <button
              className="overview-model-footer-button"
              onClick={handleMaturityClose}
            >
              Close
            </button>
          </div> */}
        </div>
      </Modal>
    </div>
  );
}));

export default Overview;
