import React, { useState, useEffect } from "react";
import "./transaction-history-model.css";
import Modal from "@mui/material/Modal";
import Loader from "react-loader-spinner";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { convertAmount, errorToast, successToast } from "../../utils/helper";
import { Client_API, Report_API } from "../../services/api";
import { ActionDropdown } from "../../components";
import { eventLog } from "../../utils/firebase";
import AdditionalInfo from "../additional_info/additional_info";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function TransactionHistory(props) {
  const [transactionHistoryData, setTransactionHistoryData] = React.useState(
    []
  );
  const [loader, setLoader] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [fileType, setFileType] = useState("pdf");
  const [transactionLoader, setTransactionLoader] = useState(false);
  const [calendarInputs, setCalendarInputs] = useState({
    input1: false,
    input2: false,
  });
  const [showDownloadReport, setShowDownloadReport] = useState(true);
  const currDate = new Date().toISOString().split("T")[0];
  const [page, setPage] = useState(props.page ? props.page : 1);
  const [nodataLeft, setNoDataLeft] = useState(false);
  const [ctaLoader, setCtaLoader] = useState(false);
  const api = new Client_API();
  const reportAPI = new Report_API();
  const reportDeliveryData = props.reportDeliveryData;

  React.useEffect(() => {
    if (props.openModel) {
      fetchTransactionHistory();
    }
    //Check for already existing request
    let duplicateReportArray = [];
    if (
      reportDeliveryData &&
      reportDeliveryData[0] != null &&
      reportDeliveryData.length
    ) {
      reportDeliveryData.forEach((item) => {
        if (item.status == 0) {
          duplicateReportArray.push(item);
        }
      });
    }
    if (duplicateReportArray.length) {
      setShowDownloadReport(false);
    }
    if (props.page) {
      setPage(props.page);
    }
  }, [props.openModel, props.page]);

  const fetchTransactionHistory = () => {
    setLoader(true);
    const payload = {
      user_uuid: props.clientUuid,
    };
    api
      .fetchClientTransactionHistory(payload)
      .then((res) => {
        if (res.kind === "ok") {
          setTransactionHistoryData(res.data);
          setLoader(false);
        } else {
          errorToast("Error in fetching client transaction history");
          setLoader(false);
        }
      })
      .catch((error) => {
        errorToast(
          "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
        );
        setLoader(false);
      });
  };

  //Make call to the record report request api
  const handleReportGeneration = async () => {
    //Validate from date, to date and file type
    if (fileType && !(fromDate || toDate)) {
      setFileType("pdf");
      setCtaLoader(false);
      return errorToast("Please select date range.");
    }
    if (fromDate && fromDate < "2023-05-15") {
      setFileType("pdf");
      setCtaLoader(false);
      return errorToast(
        "From date cannot be less than the minimum date i.e 15/05/2023."
      );
    }
    if (toDate && (toDate < "2023-05-15" || toDate > currDate)) {
      setFileType("pdf");
      setCtaLoader(false);
      return errorToast("To date cannot be greater than the current date.");
    }
    const payload = {
      investor_uuid: props.clientUuid,
      start_date: fromDate,
      end_date: toDate,
      file_type: fileType,
      mode: "ifa",
    };
    await reportAPI.recordReportRequest(payload).then((res) => {
      if (res.kind === "ok") {
        setFromDate("");
        setToDate("");
        setFileType("pdf");
        eventLog({
          type: "requested_account_statement",
          action: {
            trigger: "true",
          },
        });
        setShowDownloadReport(false);
        setCtaLoader(false);
        return successToast(
          "Your investor will receive the account statement on their registered email ID within the next 24 hours."
        );
      } else {
        setFileType("pdf");
        setCtaLoader(false);
        errorToast(res.error);
      }
    });
  };

  const handleModalClose = (e) => {
    setFromDate("");
    setToDate("");
    setFileType("");
    setNoDataLeft(false);
    props.handleClose();
  };

  const handleInputFocus = (inputName) => {
    setCalendarInputs((prevInputs) => ({
      ...prevInputs,
      [inputName]: true,
    }));
  };

  const handleInputBlur = (inputName) => {
    setCalendarInputs((prevInputs) => ({
      ...prevInputs,
      [inputName]: false,
    }));
  };

  //handle scroll page
  const handleScroll = async (e) => {
    // Math.abs(scrollHeight - (scrollTop + clientHeight)
    setNoDataLeft(false);
    const scrollHeight = e.target.scrollHeight;
    const scrollTop = e.target.scrollTop;
    const clientHeight = e.target.clientHeight;
    const bottom = Math.abs(scrollHeight - (scrollTop + clientHeight)) <= 1;

    //scroll reaches the bottom of the screen
    if (bottom) {
      setTransactionLoader(true);
      const payload = {
        user_uuid: props.clientUuid,
      };

      //call api to populate data
      await api
        .fetchClientTransactionHistory(payload, page)
        .then((res) => {
          if (res.kind === "ok") {
            if (res.data.length == 0) {
              setNoDataLeft(true);
            }
            setTransactionHistoryData((prevData) => [...prevData, ...res.data]);
            setPage(page + 1);
            setTransactionLoader(false);
          } else {
            errorToast("Error in fetching client transaction history");
            setTransactionLoader(false);
          }
        })
        .catch((error) => {
          errorToast(
            "An unexpected error occurred, please try again later. Meanwhile, if you need any assistance, reach out to us at support@1invest.in"
          );
          setTransactionLoader(false);
        });
    }
  };

    const limitDate = "2024-10-15";
    // Handles 'From'
    const handleFromDateChange = (event) => {
      const newFromDate = event.target.value;
      setFromDate(newFromDate);
  
      // Adjust 'To' date if it exceeds the limit
      if (new Date(newFromDate) <= new Date(limitDate) && new Date(toDate) > new Date(limitDate)) {
        setToDate(limitDate);
      }
    };
  
    // Handles 'To'
    const handleToDateChange = (event) => {
      const newToDate = event.target.value;
      if (fromDate && new Date(fromDate) <= new Date(limitDate) && new Date(newToDate) > new Date(limitDate)) {
        return;
      }
      setToDate(newToDate);
    };

  return (
    <Modal
      open={props.openModel}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{
        backgroundColor: "rgba(102, 102, 102, 0.72)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="transaction_history_model_div">
        <div className="transaction_history_model_header">
          <div style={{ float: "left" }}>
            <h1 className="transaction_history_model_header_title">
              Transaction History
            </h1>
          </div>
          <div
            style={{ float: "right", cursor: "pointer" }}
            onClick={handleModalClose}
          >
            <h1 className="transaction_history_model_header_title">
              <CloseIcon />
            </h1>
          </div>
        </div>
        {/* Download Statement */}
        {/* container div */}
        <div className="download_statement_container_div">
          {/* title div */}
          <h1 className="download_statement_title_div">Detailed Statement</h1>

          <h1
            className="download_statement_title_div"
            style={{
              display: !showDownloadReport ? "flex" : "none",
              textAlign: "center",
              color: "#3A86FF",
              marginTop: "0",
            }}
          >
            Your Statement Request is Underway.
          </h1>
          {/* outer div */}
          <div className="download_statement_sub_container_div">
            <h1 className="download_statement_sub_title_div">Select Date</h1>
            {/* inner div */}
            <div className="download_statement_date_selection_div">
              <div
                className="date_selection_column_div"
                style={{
                  textAlign: "left",
                }}
              >
                <input
                  className="date_picker_input"
                  type={calendarInputs.input1 ? "date" : "text"}
                  placeholder="From"
                  value={fromDate}
                  min={"2023-05-15"}
                  max={new Date().toISOString().slice(0, 10)}
                  onChange={handleFromDateChange}
                  onFocus={() => handleInputFocus("input1")}
                  onBlur={() => handleInputBlur("input1")}
                />
              </div>

              <div
                className="date_selection_column_div"
                style={{
                  textAlign: "center",
                }}
              >
                <input
                  className="date_picker_input"
                  type={calendarInputs.input2 ? "date" : "text"}
                  placeholder="To"
                  value={toDate}
                  min={"2023-05-15"}
                  max={fromDate && new Date(fromDate) <= new Date(limitDate) ? limitDate : new Date().toISOString().slice(0, 10)}
                  onChange={handleToDateChange}
                  onFocus={() => handleInputFocus("input2")}
                  onBlur={() => handleInputBlur("input2")}
                />
              </div>
              <div
                className="date_selection_column_div"
                style={{
                  textAlign: "right",
                }}
              >
                <button
                  onClick={() => {
                    setCtaLoader(true);
                    handleReportGeneration();
                  }}
                  className="ifa_transaction_statement_button"
                >
                  {ctaLoader ? (
                    <Loader
                      type="TailSpin"
                      color="#FFFFFF"
                      height={15}
                      width={15}
                    />
                  ) : (
                    "Request PDF"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="disclaimer-container disclaimer_model">
          <InfoOutlinedIcon className="info-icon" />
          <span className="disclaimer-text">
            <span className="bold-text" style={{fontWeight: "600", opacity: "0.9", fontSize: "16px", color: "#404040"}}>Disclaimer :</span> SOA periods are split wef 16-Oct-2024 based on data received from NBFC-P2P partner RNVP Technology Private Limited.
          </span>
        </div>
        <div
          onScroll={handleScroll}
          className="transaction_history_model_main_container_div"
        >
          <div className="transaction_history_model_line_div">
            <hr style={{ border: "1px solid #EFEFEF" }} />
          </div>
          {/* title */}
          {!loader ? (
            <React.Fragment>
              {transactionHistoryData &&
                transactionHistoryData.map((item, key) => (
                  <React.Fragment>
                    <div
                      className="transaction_history_model_main_div"
                      index={key}
                    >
                      <span style={{ float: "left", width: "80%" }}>
                        <div className="transaction_history_model_left_div">
                          <div className="arrow_downward_icon_outer_circle_div">
                            <div className="arrow_downward_icon_div">
                              {item.type == 0 ||
                              item.type == 3 ||
                              item.type == 5 ? (
                                <ArrowUpwardIcon
                                  sx={{
                                    color: "white",
                                    fontSize: "14px",
                                    display: "inline",
                                  }}
                                />
                              ) : (
                                <ArrowDownwardIcon
                                  sx={{
                                    color: "white",
                                    fontSize: "14px",
                                    display: "inline",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="transaction_history_model_info">
                            <h1 className="transaction_history_model_title">
                              {item.narration_title}
                            </h1>
                            <h1 className="transaction_history_model_title_description">
                              {item.description.includes("Rs.") ? (
                                <h1 className="transaction_history_model_title_description">
                                  {item.description.split("Rs.")[0]}
                                  <span
                                    style={{
                                      fontFamily: "'Open Sans', sans-serif",
                                      fontWeight: 100,
                                      fontSize: "12px",
                                      color: "#737373",
                                    }}
                                  >
                                    ₹
                                  </span>
                                  {item.description.split("Rs.")[1]}
                                </h1>
                              ) : (
                                item.description
                              )}
                            </h1>
                          </div>
                        </div>
                      </span>

                      <span style={{ float: "right", textAlign: "right" }}>
                        <div className="transaction_history_model_right_div">
                          <h1 className="transaction_history_model_title">
                            {item.type == 0 || item.type == 3 || item.type == 5
                              ? ""
                              : "-"}{" "}
                            <span
                              style={{
                                fontFamily: "'Open Sans', sans-serif",
                                fontWeight: 600,
                              }}
                            >
                              ₹
                            </span>{" "}
                            {convertAmount(item.amount)}
                          </h1>
                          <h1 className="transaction_history_model_title_description">
                            {item.created_at.formatted_date}
                          </h1>
                        </div>
                      </span>                  
                      {/* {item.type == 4 ? <AdditionalInfo iIcon={"left"} information={"The requested withdrawal amount is locked and unavailable for investment. Please verify the status in your Withdrawal Requests."} /> : null} */}
                    </div>
                    <div className="transaction_history_model_line_div">
                      <hr style={{ border: "1px solid #EFEFEF" }} />
                    </div>
                  </React.Fragment>
                ))}
            </React.Fragment>
          ) : (
            <div className="transaction_history_model_loader_div">
              <Loader type="TailSpin" color="#414141" height={50} width={50} />
            </div>
          )}
          <div className="transaction_history_model_loader_loader">
            {transactionLoader ? (
              <Loader type="TailSpin" color="#414141" height={20} width={20} />
            ) : null}
            {nodataLeft ? <p>No Transacation's Left</p> : null}
          </div>
        </div>
      </div>
    </Modal>
  );
}