import React, { useEffect, useState } from "react";
import { errorToast } from "../../utils/helper";
import { Client_API } from "../../services/api/Clients/clients-api";
import { useHistory, useLocation } from "react-router-dom";
import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


// styles 
const tableContainer = {
  marginTop: "20px",
  width: "92%",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  borderTop: "1px solid #c7c7c7",
  borderLeft: "1px solid #c7c7c7",
  borderRight: "1px solid #c7c7c7",
  alignSelf: "center",
  overflow: "hidden",
};

const tableHeader = {
  backgroundColor: "#f6f6f6",
  // fontWeight: "600",
  fontSize: 16,
  fontFamily: "Asap",
  color: "#1f1f1f",
  paddingTop: "15px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "15px",
  width: 200,
};

const tableRow = {
  fontSize: 14,
  fontFamily: "Asap",
  fontWeight: "400",
  color: "#1c1c1c",
  paddingTop: "10px",
  paddingLeft: "10px",
  paddingRight: "0px",
  paddingBottom: "10px",
};

const containerStyle = {
  width: "92%",
};

const cardStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  maxWidth: "250px",
  borderRadius: "5px",
  padding: "13px",
  gap: "5px",
  border: "1px solid #cdc5c5",
  boxSizing: "border-box",
};

const labelStyle = {
  color: "#4f4b4b",
  fontSize: "16px",
  padding: "0 4px",
};

const valueContainerStyle = {
  color: "#2f2b2b",
  display: "flex",
  alignItems: "center",
};

const valueStyle = {
  fontSize: "28px",
  marginLeft: "5px",
};

const currencyIconStyle = {
  color: "#2f2b2b",
};


const TranchReport = () => {
  const param = useLocation();
  const api = new Client_API();
  const [loading, setLoading] = useState(false)
  const [repaymentList, setRepaymentList] = useState([])
  const [tranchData, setTranchData] = useState({})
  console.log("param ---> ",param)

  

  const getTranchReportApi = async () => {
    const payload = {
      user_id: param.state.uuid,
      source : "dashboard"
    };
    setLoading(true)
    api
      .getTranchReport(payload)
      .then((res) => {
        if (res.kind == "ok") {
          console.log(res);
          setTranchData(res?.data || [])
          console.log(res?.data)
          Object.keys(res?.data).forEach((item)=>{
            console.log(`${item} --> ${res.data[`${item}`]}`)
          })

          const repayments = Object.keys(res?.data).map((item) => {
            if (item.startsWith("repayment")) {
              return { repayment: item.split("_")[1], amount: res?.data[item] };
            }
            return null; // Return null for non-matching items, we can filter this out later
          }).filter(Boolean);

          setRepaymentList(repayments)
        } else {
          console.log("first");
        }
      })
      .catch((error) => {
        errorToast("Error while fetching Net Worth Certificate.");
      }).finally(()=>{
          setLoading(false)
      })
  };

  useEffect(()=>{
    getTranchReportApi()
  },[])

  return (
    <div className="clientkyc_main_div" style={{ marginTop: "10px" }}>
      {/* <div style={containerStyle}>
        <div style={cardStyle}>
          <span style={labelStyle}>Total Lent Account</span>
          <div style={valueContainerStyle}>
            <CurrencyRupeeIcon style={currencyIconStyle} />
            <span style={valueStyle}>{tranchData?.invested_amount || "00"}</span>
          </div>
        </div>
      </div> */}

      <TableContainer style={tableContainer}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="left" padding="none" style={tableHeader}>
                Tranch No.
              </TableCell>
              <TableCell align="left" padding="none" style={tableHeader}>
                Amount
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>

      {/* Wrapping TableBody with a scrollable container */}
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          <Table>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : repaymentList.length ? (
                repaymentList.map((item, index) => (
                  <TableRow
                    key={item.uuid}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                      cursor: "pointer",
                    }}
                  >
                    <TableCell
                      style={{
                        ...tableRow,
                        width: "50%",
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                      }}
                      align="left"
                    >
                      {item?.repayment}
                    </TableCell>
                    <TableCell
                      style={{
                        ...tableRow,
                        width: "50%",
                        backgroundColor: index % 2 === 0 ? "#fafafa" : "#fff",
                      }}
                      align="left"
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <CurrencyRupeeIcon style={{ height: "20px" }} />
                        {item?.amount}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={2} style={tableRow}>
                    No data found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        </TableContainer>
        <div className="disclaimer-container" style={{width: "90%", marginTop:"20px"}}>
          <InfoOutlinedIcon className="info-icon" />
          <span className="disclaimer-text">
            <span className="bold-text" style={{fontWeight: "600", opacity: "0.9", fontSize: "16px", color: "#404040"}}>Disclaimer :</span> P2P lending is subject to risks involved with the corresponding onward lending and performance of the loans will have a direct impact on the status of the investors repayment.  Any lending platform  like 1invest or the lender can not guarantee that the loan amount recoverey from the borrower.<br/><br/>Maturity/Repayments are offered on a best effort basis under the revised set of RBI guidelines related to P2P lending and 1Invest shall not be directly liable or responsible for any delay in or non-availability of the same.
          </span>
        </div>
    </div>
  );
  
};

export default TranchReport;
